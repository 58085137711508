import { useState } from "react";
import "./style.scss";
import { MinusSquare, PlusSquare } from "react-feather";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  link?: string;
  children?: React.ReactNode;
};

export default function DirectoryItem(props: Props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="DirectoryItem">
      {props.children && (
        <div
          className="DirectoryItem-toggle"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <MinusSquare size="1.5rem" strokeWidth={1.5} /> : <PlusSquare size="1.5rem" strokeWidth={1.5} />}
        </div>
      )}
      {props.link
        ? <Link to={props.link} className="DirectoryItem-link">{props.title}</Link>
        : <div className="DirectoryItem-title" onClick={() => setExpanded(!expanded)}>{props.title}</div>
      }
      
      {expanded && (
        <div className="DirectoryItem-children">{props.children}</div>
      )}
    </div>
  );
}