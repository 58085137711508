import './grid-box.scss';

import { ReactNode } from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

export type GridBoxProps = {
  id: string,
  gridAreaL?: string,
  gridAreaM?: string,
  color?: string,
  children?: ReactNode,
  className?: string,
  style?: Object,
  disabled?: boolean,
  onClick?: () => void
}

export default function GridBox(props: GridBoxProps) {
  const gridPositionStyle:{ display?: string; gridArea?: string; } = {};
  const lgLayoutMinWidth = 1280;
  const mdLayoutMinWidth = 768;
  const isLg = useMediaQuery({ minWidth: lgLayoutMinWidth });
  const isMd = useMediaQuery({ 
    minWidth: mdLayoutMinWidth,
    maxWidth: lgLayoutMinWidth - 1
  });
  if (isLg) {
    gridPositionStyle.gridArea = props.gridAreaL;
    props.gridAreaL?.trim() !== '' || (gridPositionStyle.display = 'none');
  }
  else if (isMd) {
    gridPositionStyle.gridArea = props.gridAreaM;
    props.gridAreaM?.trim() !== '' || (gridPositionStyle.display = 'none');
  }
  
  const boxStyle = {
    "--grid-box-color": props.color ? props.color : '#aaa',
    ...gridPositionStyle,
    ...props.style
  }

  const handleClick = () => {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  }

  return (
    <div 
      className={cn('GridBox', props.className, {"GridBox-disabled": props.disabled})} 
      style={boxStyle} 
      id={props.id}
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
}