import './style.scss';

import { useHistory, useParams } from 'react-router-dom';
import Header from '../header';
import Matrix from './matrix';
import DocViewer from '../docViewer';
import { CancerType, Compound, GridBox, GridGroup, MolecularTarget, Phase } from '../../modules/api';
import ProgramNav from './nav';
import GridBoxMenu from './grid-box-menu';
import { History } from 'history';
import { parseWithLinks } from '../../modules/htmlParser';
import Overlay from '../overlay';
import { useContext } from 'react';
import VersionContext from '../../contexts/versionContext';
import SessionContext from '../../contexts/sessionContext';

type Props = {
  cancerTypes: CancerType[],
  molecularTargets: MolecularTarget[],
  phases: Phase[],
  compounds: Compound[],
  gridBoxes: GridBox[],
  defaultMatrix: string,
  title: string,
  footer: string
}

export default function Program(props: Props) {
  const version = useContext(VersionContext);
  const session = useContext(SessionContext);

  // Old format: /program/matrix/box/compound
  // New format: /program/matrix/group/box/compound
  const params = useParams<{p1: string, p2?: string, p3?: string, p4?: string}>();
  const history = useHistory();

  // Group is optional for legacy URLs.
  // Group is a slug of cancer type, molecular target, or phase.
  // Check if the first param is a group.
  const maybeGroup = params.p2?.toLowerCase();
  const activeGroup = props.cancerTypes.find(c => c.slug === maybeGroup)?.slug ||
    props.molecularTargets.find(t => t.slug === maybeGroup)?.slug ||
    props.phases.find(p => p.slug === maybeGroup)?.slug;
  
  const matrix = params.p1;
  const box = activeGroup === undefined ? params.p2 : params.p3;
  const compound = activeGroup === undefined ? params.p3 : params.p4;
  
  matrix || history.replace('/program/' + props.defaultMatrix);

  const currentGridBox = props.gridBoxes.find(c => c.id === box);
  const currentCompound = props.compounds.find(c => c.id === compound);

  const gridGroups:{[key: string]: GridGroup[]} = {
    cancer: props.cancerTypes,
    target: props.molecularTargets,
    phase: props.phases
  }

  const currentGridBoxEl = box ? document.getElementById(`grid-box-${box}`) : undefined;
  const gridBoxMenuStyles: React.CSSProperties = {}
  if (currentGridBoxEl) {
    gridBoxMenuStyles.left = `calc(var(--nav-width) + ${currentGridBoxEl.offsetLeft}px)`
    gridBoxMenuStyles.top = `calc(var(--header-height) + var(--announcement-height) + 2rem + ${currentGridBoxEl.offsetTop}px)`
    gridBoxMenuStyles.width = `${currentGridBoxEl.offsetWidth}px`
    gridBoxMenuStyles.height = `${currentGridBoxEl.offsetHeight}px`
  }

  return <div className="Program">
    <div className="overlay" />
    <div className="page-content">
      <Header back='/menu'>{props.title}</Header>
      <ProgramNav />
    
      <div className="Program-content">
        {matrix && gridGroups[matrix] && <Matrix
          key={matrix}
          slug={matrix}
          gridGroups={gridGroups[matrix]}
          gridBoxes={props.gridBoxes.filter(gb => gb.matrix === matrix)}
          activeGroup={activeGroup}
          onGridBoxClick={(gridBox) => onGridBoxClick(gridBox, matrix, history, activeGroup)}
          onGroupClick={(group) => onGroupClick(group, matrix, history, activeGroup)}
        />}

        {props.footer && <div className="Program-footer">{parseWithLinks(props.footer)}</div>}
      </div>
      
      {currentCompound && 
        <DocViewer 
          url={currentCompound.document} 
          title={currentCompound.title} 
          onClose={()=>goBack()}
          disclaimer={(session.isHcp === true) ? version?.compoundDisclaimer : ''}
          contentType="Compound"
        />
      }

      {currentGridBox && matrix && <>
        <Overlay />
        <GridBoxMenu 
          gridBox={currentGridBox} 
          onCompoundClick={(compound) => onCompoundClick(
            compound, 
            currentGridBox,
            matrix,
            history,
            activeGroup
          )}
          onClose={() => onGridBoxMenuClose(matrix, history, activeGroup)}
          initialStyles={gridBoxMenuStyles}
        />
      </>}
    </div>
  </div>
}

const onGridBoxClick = (gridBox: GridBox, matrix: string, history: History, group?: string,) => {
  const groupSegment = group ? `/${group}` : '';
  if (gridBox.compoundIds?.length) {
    let path = `/program/${matrix}${groupSegment}/${gridBox.id}`;
    if (gridBox.compoundIds.length === 1) {
      path += '/' + gridBox.compoundIds[0]
    }
    history.push(path);
  }
}

const onGroupClick = (group: GridGroup, matrix: string, history: History, currentGroup?: string) => { 
  const groupSegment = group.slug === currentGroup ? '' : `/${group.slug}`;
  history.push(`/program/${matrix}${groupSegment}`);
}

const onCompoundClick = (compound: Compound, gridBox: GridBox, matrix: string, history: History, group?: string) => {
  const groupSegment = group ? `/${group}` : '';
  history.push(`/program/${matrix}${groupSegment}/${gridBox.id}/${compound.id}`);
}

const onGridBoxMenuClose = (matrix: string, history: History, group?: string) => {
  const groupSegment = group ? `/${group}` : '';
  history.push(`/program/${matrix}${groupSegment}`);
}

const goBack = function () {
  if (window.history.length > 2) {
    window.history.back();
  }
  else {
    window.location.pathname = ''
  }
}