import "./matrix.scss";

import {
  GridGroup as GridGroupData,
  GridBox as gridBoxData,
} from "../../modules/api";
import GridBox from "./grid-box";
import CompoundGridBox from "./compound-grid-box";
import GridGroup from "./grid-group";
import React from "react";
import { useMediaQuery } from "react-responsive";

type Props = {
  slug: string;
  gridGroups: GridGroupData[];
  gridBoxes: gridBoxData[];
  activeGroup?: string;
  onGridBoxClick: (gridBox: gridBoxData) => void;
  onGroupClick: (gridGroup: GridGroupData) => void;
};

export default function ProgramMatrix(props: Props) {
  // const [expandedGroup, setExpeandedGroup] = useState<string>();
  const isSmScreen = useMediaQuery({ maxWidth: "767px" });

  const isDisabledGroup = (group: GridGroupData) => {
    return (
      props.activeGroup !== undefined &&
      group.slug !== props.activeGroup
    );
  };

  const handleGroupClick = (group: GridGroupData) => {
    // @todo unify expanded group and active group
    // setExpeandedGroup(expandedGroup === group.id ? undefined : group.id);
    props.onGroupClick(group);
  };

  // CSS Class to indicate the active group if any.
  const activeGroupClass = props.activeGroup
    ? ` active-group--${props.activeGroup}`
    : "";

  return (
    <div className={`ProgramMatrix matrix--${props.slug}${activeGroupClass}`}>
      {props.gridGroups
        .filter((g) => g.gridAreaL || g.gridAreaM)
        .map((g) => (
          <React.Fragment key={g.id}>
            <GridGroup
              key={g.id}
              {...g}
              disabled={isDisabledGroup(g)}
              onClick={() => handleGroupClick(g)}
            />
            {props.gridBoxes
              .filter(
                (gb) =>
                  [
                    gb.cancerTypeId,
                    ...gb.phaseIds,
                    ...gb.molecularTargetIds,
                  ].includes(g.id) &&
                  (g.slug === props.activeGroup || !isSmScreen)
              )
              .map((gb) => (
                <CompoundGridBox
                  key={gb.id}
                  id={`grid-box-${gb.id}`}
                  gridAreaL={gb.gridAreaL}
                  gridAreaM={gb.gridAreaM}
                  color={gb.color}
                  data={gb}
                  disabled={isDisabledGroup(g)}
                  onClick={() => props.onGridBoxClick(gb)}
                />
              ))}
          </React.Fragment>
        ))}

      {props.gridGroups
        .filter((g) => g.image)
        .map((g) => (
          <React.Fragment key={g.id}>
            {(g.imageGridAreaL || g.imageGridAreaM) && (
              <GridBox
                {...g}
                gridAreaL={g.imageGridAreaL}
                gridAreaM={g.imageGridAreaM}
                key={`grid-image-${g.id}`}
                id={`grid-image-${g.slug}`}
                className="grid-image"
                style={{ backgroundImage: `url(${g.image})` }}
                disabled={isDisabledGroup(g)}
                onClick={() => handleGroupClick(g)}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  );
}
