import "./style.scss";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { debounce } from "lodash-es";
import {
  loadSearchResults,
  SearchResult as SearchResultData,
} from "../../modules/api";
import DocViewer from "../docViewer";
import SearchResult from "../searchResult";
import StaticContent from "../staticContent";
import { parseWithLinks } from "../../modules/htmlParser";
import Video from "../video";
import { recordEvent } from "../../modules/stats";
import { useContext } from "react";
import VersionContext from "../../contexts/versionContext";
import SessionContext from "../../contexts/sessionContext";
import { List } from "react-feather";

type Props = {
  directory: React.ReactNode;
};

export default React.forwardRef((props: Props, ref) => {
  const version = useContext(VersionContext);
  const session = useContext(SessionContext);

  const [results, setResults] = useState<SearchResultData[]>();
  const [currentResult, setCurrentResult] = useState<SearchResultData>();

  const searchEl = useRef<HTMLDivElement>(null);
  const searchInputEl = useRef<HTMLInputElement>(null);

  const hideSearch = () => {
    searchEl.current?.classList.remove("searching");
    searchEl.current?.classList.remove("active");
    searchInputEl.current && (searchInputEl.current.value = "");
    setResults(undefined);
  };

  const handleInputFocus = () => {
    setShowDirectory(false);
    searchEl.current !== null &&
    (searchEl.current.className = "Search active")
  }

  const doSearch = (
    keyword: string,
    callback: (results?: SearchResultData[]) => void
  ) => {
    keyword = keyword.trim();
    if (version !== undefined && keyword.length > 1) {
      searchEl.current?.classList.remove("no-results");
      searchEl.current?.classList.add("searching");
      loadSearchResults(
        version.slug,
        keyword,
        (results: SearchResultData[]) => {
          searchEl.current?.classList.remove("searching");
          if (results.length === 0) {
            searchEl.current?.classList.add("no-results");
          }
          callback(results);
        }
      );
    } else {
      callback(undefined);
    }
  };
  const debouncedDoSearch = debounce(doSearch, 1000);

  useEffect(() => {
    currentResult &&
      recordEvent("Content searches", currentResult.title, currentResult.type);
  }, [currentResult]);

  const disclaimer =
    version !== undefined &&
    version.compoundDisclaimer &&
    currentResult &&
    currentResult.type === "Compound" &&
    session.isHcp === true
      ? version.compoundDisclaimer
      : "";

  const [showDirectory, setShowDirectory] = useState(false);
  const handleDirectoryToggleClick = () => {
    setShowDirectory(!showDirectory);
  }

  return (
    <div className="Search" ref={searchEl}>
      <div className="Search-overlay" onClick={hideSearch} />
      <div className="Search-window">
        <button className="Search-close" onClick={hideSearch} />
        <div
          className={
            "Search-directory-toggle" + (showDirectory ? " active" : "")
          }
          onClick={handleDirectoryToggleClick}
        >
          <List size="2em" />
        </div>
        <div className="Search-input">
          <input
            type="text"
            ref={searchInputEl}
            placeholder="Search"
            onChange={(e) => {
              debouncedDoSearch(e.target.value, setResults);
            }}
            onFocus={handleInputFocus}
          />
          <span className="icon" />
        </div>
        {results && results?.length > 0 && !showDirectory && (
          <div className="Search-results">
            {results.map((r) => (
              <SearchResult
                className="Search-result"
                key={r.id}
                data={r}
                onClick={() => setCurrentResult(r)}
              />
            ))}
          </div>
        )}
        {showDirectory && (
          <div className="Search-directory">{props.directory}</div>
        )}
        {currentResult && currentResult.document && (
          <DocViewer
            url={currentResult.document}
            title={currentResult.title}
            contentType={currentResult.type}
            disclaimer={disclaimer}
            onClose={() => setCurrentResult(undefined)}
          />
        )}
        {currentResult && !currentResult.document && currentResult.videoUrl && (
          <Video
            className="Search-content"
            title={currentResult.title}
            url={currentResult.videoUrl}
            disclaimer={currentResult.videoDisclaimer}
            onClose={() => setCurrentResult(undefined)}
          />
        )}
        {currentResult &&
          !currentResult.document &&
          !currentResult.videoUrl &&
          currentResult.text && (
            <StaticContent
              className="Search-content"
              title={currentResult.title}
              onClose={() => setCurrentResult(undefined)}
            >
              {parseWithLinks(currentResult.text)}
            </StaticContent>
          )}
      </div>
    </div>
  );
});
