import './style.scss';
import Logo from '../../assets/images/logo.svg';

import { useEffect, useRef, useState } from 'react';
import { Switch, Route, Link, useLocation, withRouter, RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import ReactGA from 'react-ga';
import { parseWithLinks }  from '../../modules/htmlParser';
import { isAuthenticated, isHcp } from '../../modules/auth';
import Announcement, { isAnnouncementShown } from '../announcement';
import Auth from '../auth';
import Home from '../home';
import DocViewer from '../docViewer';
import Menu from '../menu';
import Program from '../program';
import Resources from '../resources';
import Search from '../search';
import StaticContent from '../staticContent';
import Trials from '../trials';
import Video from '../video';
import Preloader from '../preloader';
import { CSSTransition } from 'react-transition-group';
import { Version, loadVersion, useApiData } from '../../modules/api';
import { VersionContextProvider } from '../../contexts/versionContext';
import parse from 'html-react-parser';
import { usePathClass } from '../../modules/routing';
import { getConfig } from '../../modules/config';
import ExternalLinkWarning from '../externalLinkWarning';
import Rules from '../rules';
import { useCookies } from 'react-cookie';
import { goBack } from '../../modules/navigation';
import { Session, SessionContextProvider } from '../../contexts/sessionContext';
import Directory from '../directory';

function App({history}: RouteComponentProps) {
  useEffect(() => {
    ReactGA.pageview(history.location.pathname);
  }, [history.location.pathname]);

  const versionName = getConfig('REACT_APP_VERSION')!;

  const [version, setVersion] = useState<Version>();
  useEffect(() => { loadVersion(versionName, setVersion) }, [versionName]);

  const allApiData = useApiData(versionName) 
  const {
    cancerTypes,
    compounds,
    gridBoxes,
    documents,
    molecularTargets,
    phases,
    resourceCategories,
    staticContent,
    tiles,
    trials,
    videos
  } = allApiData;

  // @todo Implement with Suspense.
  const allDataLoaded = Object.values(allApiData).filter(d => d === undefined).length === 0;

  const [cookies] = useCookies(['pod_geo', 'pod_pw'])

  const appEl = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const pathClass = usePathClass();

  const onAppScroll = () => {
    if (appEl !== undefined && appEl.current) {
      appEl.current?.scrollTop > 10 
        ? appEl.current.classList.add('scrolled')
        : appEl.current.classList.remove('scrolled')
    }
  }

  // Make sure the page is scrolled top upon navigation
  const section = location.pathname.split('/')[1];
  useEffect(() => {
    appEl && appEl.current && (appEl.current.scrollTop = 0);
  }, [section])

  const searchEl = useRef();

  const announcementClass = 'has-announcement';
  const hideAnnouncement = () => appEl.current && appEl.current.classList.remove(announcementClass);

  const [session, setSession] = useState<Session>({isAuthenticated: false});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSession({
      isAuthenticated: isAuthenticated(cookies, version?.authMethod, version?.password, version?.hcpPassword),
      authMethod: version?.authMethod,
      isHcp: version && version.hcpPassword !== undefined && isHcp(cookies, version?.hcpPassword)
    })
  }, [version, cookies])

  const cssClasses = cn(
    ["App", pathClass], 
    {
      authenticated: session.isAuthenticated,
      [announcementClass]: version?.announcement && isAnnouncementShown()
    }
  );

  // Warn a visitor about leaving Pfizer ecosystem
  const [leavingToUrl, setLeavingToUrl] = useState<string>();
  useEffect(() => {
    if (!version) return;

    const catchOutboundLinks = (e: MouseEvent) => {
      // @ts-ignore
      let leavingTo = e?.target.href;
      const whiteList = version.leavingWarningWhitelist.split(',');
      whiteList.push(window.location.hostname);
      whiteList.forEach(url => {
        leavingTo && leavingTo.includes(url) && (leavingTo = '');
      });
      if (leavingTo) {
        setLeavingToUrl(leavingTo);
        e.preventDefault();
      }
    }
    document.addEventListener("click", catchOutboundLinks);
    
    // Unset callback.
    return () => document.removeEventListener("click", catchOutboundLinks);
  }, [version]);

  return (
      <CSSTransition timeout={1500} in={location.pathname === '/'} classNames="home" nodeRef={appEl}>
        <VersionContextProvider value={version}>
          <SessionContextProvider value={session}>
            <div id="app" className={cssClasses} ref={appEl} onScroll={(onAppScroll)}>
              {version?.css && <style>{parse(version?.css)}</style>}
              {(version === undefined || (session.isAuthenticated && !allDataLoaded)) && <Preloader />}
              {version?.rules && <Rules content={version.rules} />}
              <Auth />
              <div className="App-header-bg" />
              <div className="Branding">
                <Link className="Logo" to="/"><img src={Logo} alt="Pfizer Oncology" /></Link>
              </div>
              {version?.leavingWarning && leavingToUrl && 
                <ExternalLinkWarning 
                  content={version.leavingWarning}
                  onAccept={() => { window.open(leavingToUrl); setLeavingToUrl(undefined) }}
                  onDismiss={()=> setLeavingToUrl(undefined)}
                />
              }
              {allDataLoaded && version && (<>
                <CSSTransition mountOnEnter timeout={5000} in={['/', '/menu'].includes(location.pathname)} classNames="fade" nodeRef={searchEl}>
                <Search
                  ref={searchEl}
                  directory={<Directory {...allApiData} />}
                />
                </CSSTransition>
                {version.announcement && <Announcement onClose={()=>hideAnnouncement()}>
                  {parseWithLinks(version.announcement)}
                </Announcement>}
                <Switch>
                  {documents && documents.map(d => 
                    <Route key={d.id} path={`/${d.path}`}>
                      <DocViewer url={d.document} title={d.title} contentType="document" onClose={goBack} />
                    </Route>
                  )}
                  {staticContent && staticContent.map(c => 
                    <Route key={c.id} path={`/${c.path}`}>
                      <StaticContent {...c} onClose={goBack}>{parseWithLinks(c.text)}</StaticContent>
                    </Route>
                  )}
                  {videos && videos.map(v => 
                    <Route key={v.id} path={`/${v.path}`}>
                      <Video {...v} onClose={goBack} />
                    </Route>
                  )}
                  {trials && cancerTypes && <Route path={['/trials/:id', '/trials']}>
                    <Trials trials={trials} cancerTypes={cancerTypes}/>
                  </Route>}
                  {resourceCategories && documents && videos && <Route path={'/approaches'}>
                    <Resources categories={resourceCategories} resources={[...documents, ...videos]}/>
                  </Route>}
                  {tiles && <Route path="/menu">
                    <Menu tiles={tiles}/>  
                  </Route>}
                  {cancerTypes && molecularTargets && compounds && gridBoxes && phases &&
                    <Route path={['/program/:p1?/:p2?/:p3?/:p4?']}>
                      <Program
                        cancerTypes={cancerTypes}
                        molecularTargets={molecularTargets}
                        phases={phases}
                        compounds={compounds}
                        gridBoxes={gridBoxes}
                        defaultMatrix='cancer'
                        title={version.programTitle}
                        footer={version.programFooter}
                      />
                    </Route>
                  }
                  <Route path="/"><Home content={parseWithLinks(version.homeText)} /></Route>
                </Switch>
                <div className="Copyrights">{parseWithLinks(version.copyrights)}</div>
              </>)}
            </div>
          </SessionContextProvider>
        </VersionContextProvider>
      </CSSTransition>
  );
}

export default withRouter(App);