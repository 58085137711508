import { orderBy } from "lodash-es";
import {
  Compound,
  MolecularTarget,
  Phase,
  ResourceCategory,
  Trial,
  Video,
  Document as DocumentData,
} from "../../modules/api";
import { GridBox } from "../../modules/api";
import { CancerType } from "../../modules/api";
import "./style.scss";
import DirectoryItem from "../directoryItem";

type Props = {
  cancerTypes?: CancerType[];
  molecularTargets?: MolecularTarget[];
  phases?: Phase[];
  compounds?: Compound[];
  gridBoxes?: GridBox[];
  documents?: DocumentData[];
  resourceCategories?: ResourceCategory[];
  trials?: Trial[];
  videos?: Video[];
};

export default function Directory(props: Props) {
  const cancerTypes = orderBy(props.cancerTypes, "title");
  const molecularTargets = orderBy(props.molecularTargets, "title");
  const phases = orderBy(props.phases, "title");

  return (
    <div className="Directory">
      <div className="Directory-content">
        {cancerTypes && (
          <DirectoryItem title="Cancer Type">
            {cancerTypes.map((cancerType) => (
              <DirectoryItem
                title={cancerType.title}
                link={`/program/cancer/${cancerType.slug}`}
                key={cancerType.id}
              ></DirectoryItem>
            ))}
          </DirectoryItem>
        )}

        {molecularTargets && (
          <DirectoryItem title="Molecular Target">
            {molecularTargets.map((molecularTarget) => (
              <DirectoryItem
                title={molecularTarget.title}
                link={`/program/target/${molecularTarget.slug}`}
                key={molecularTarget.id}
              ></DirectoryItem>
            ))}
          </DirectoryItem>
        )}

        {phases && (
          <DirectoryItem title="Phase">
            {phases.map((phase) => (
              <DirectoryItem
                title={phase.title}
                link={`/program/phase/${phase.slug}`}
                key={phase.id}
              ></DirectoryItem>
            ))}
          </DirectoryItem>
        )}
      </div>
    </div>
  );
}
