import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { getConfig } from './modules/config';
import App from './components/app';
import reportWebVitals from './modules/reportWebVitals';

const gaTrackingId = getConfig('REACT_APP_GA_ID');
gaTrackingId &&
  ReactGA.initialize(gaTrackingId, {
    debug: getConfig('REACT_APP_GA_DEBUG') === 'true',
  });

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
